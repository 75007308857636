import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Promos from './views/Promos';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>  
          <Route exact path="/promos/:slug/">
            <Promos />
          </Route> 
        </Switch>
      </Router>
    </div>
  );
}

export default App;
