import React, {useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import axios from 'axios'
import logo from '../static/logo.JPG';

import bancnet from '../static/bancnet.png';
import gcash from '../static/gcash.png';
import jcb from '../static/jcb.png';
import mastercard from '../static/mastercard.png';
import paymaya from '../static/paymaya.png';
import shopeepay from '../static/shopeepay.png';
import visa from '../static/visa.png';


import 'bootstrap/dist/css/bootstrap.css'
import { Facebook, Instagram } from 'react-bootstrap-icons';
function Promos() {
  let { slug } = useParams();
  const [promo,setPromo] = useState(null)  
  const [loading, setLoading] = useState('Please wait...')
  const [share, setShare] = useState(null)

  useEffect(() => {
	if(!slug) {
		return (<div></div>)
	}

	axios.get(`https://www.puremart.ph/index.php/wp-json/wp/v2/posts?slug=${slug}/`).then((res) => {
		const data = res.data[0]
		if(data == undefined) {
			setLoading('Data not found!')
		}
		setPromo(data)
		setShare(`https://www.facebook.com/sharer/sharer.php?u=https://anniversaya.puremart.ph/promos/${slug}`)
	})
	
  },[])


  const banner = () => {

  	if(promo) {
  		if(promo.fimg_url) {
  		 return <img className="img-fluid" src={promo.fimg_url} alt={promo.title} />
  		}
  	}
  	return ''
  }

  const cashless = () => {
        const imgs = [gcash,paymaya,shopeepay,visa,bancnet,mastercard,jcb]
        return (
            <div className='row'>
                {imgs.map((img) => {
                    return <div className='col-lg-3 col-md-4 col-xs-6' key={img}>
                        <div className="client-logo">  <img src={img} alt="" className="img-fluid" alt="" />  </div>
                    </div>
                })}
            </div>
        )
  }


  const content = () => {

  	if(promo) {
  		return <section id="promos" className="mt-5">
                        <div className="container ">
                            <div className="row gx-5 align-items-center">
                                {banner()}
                              {/* <img className="img-fluid" src={this.props.fimg_url} alt="Logo" /> */}
                             <h3 className="text-center mt-3 fs-1 fw-bolder font-weight-bold header-title"> {promo.title.rendered}</h3>
                            </div>
                            <div  dangerouslySetInnerHTML={{ __html: promo.content.rendered }} />

                            <a className='btn btn-primary' href={share}  target="_blank">
                             Share on Facebook  <Facebook color="white" size={18} />
                            </a>
                        </div>
                    </section>

  	}
  	return <section id="promos" className="mt-5">
				<div className="container ">
                    <div className="row gx-5 align-items-center">
                            	{loading}
                    </div>
                </div>
  			</section>
  }


  const getYear = () => {
        return new Date().getFullYear()
  }


  return (<>
  	<header>
       <div style={{borderBottom: "25px solid #1f994a"}} className="container-fluid ">
           <div className="centered d-flex flex-column text-center order-lg-2 py-1">
                <a className="navbar-brand " href="#">
                   <img alt="puremart logo" width="550px;" className="img-fluid " src={logo} alt="Logo" />
                </a>
            </div>
        </div>
   </header>
   <main>
   	{content()}
   	<section id="clients" className="section-bg">
        <div className="container">
            <div className="section-header">
                <h3>Cashless Payments</h3>
                <p>Now Available</p>
            </div>
            <div className="row no-gutters clients-wrap clearfix wow fadeInUp" >
               {cashless()}
            </div>
        </div>
     </section>
  
   </main>
   <footer className="footer-07">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <h2 className="footer-heading"><a href="#" className="logo">PureMart<br/><span className='fs-6'>Ang Inyong Bagong Kapitbahay</span></a></h2>
                                <ul className="ftco-footer-social p-0">
                                    <li className="ftco-animate"> <a href="https://www.facebook.com/Puremart.ph/" target='_blank'>  <Facebook color="white" size={36} /> </a></li>
                                    <li className="ftco-animate"> <a href="https://www.instagram.com/puremartph/" target='_blank'>  <Instagram  className="mr-4" color="white" size={36} /> </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12 text-center">
                                <p className="copyright">
                                   Copyright &copy;{getYear()} All rights reserved | PureMart
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>

  </>);
}

export default Promos;
